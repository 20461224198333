import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Hakkımızda ve Hizmetlerimiz | DriveNow Araç Kiralama
			</title>
			<meta name={"description"} content={"DriveNow hakkında daha fazla bilgi edinin, kalitemize olan bağlılığımızı ve sunduğumuz geniş araç kiralama hizmetlerini keşfedin. Araç kiralama ihtiyaçlarınız için neden tercih edilen seçenek olduğumuzu öğrenin."} />
			<meta property={"og:title"} content={"Hakkımızda ve Hizmetlerimiz | DriveNow Araç Kiralama"} />
			<meta property={"og:description"} content={"DriveNow hakkında daha fazla bilgi edinin, kalitemize olan bağlılığımızı ve sunduğumuz geniş araç kiralama hizmetlerini keşfedin. Araç kiralama ihtiyaçlarınız için neden tercih edilen seçenek olduğumuzu öğrenin."} />
			<link rel={"shortcut icon"} href={"https://kz-wintograce.com/img/537457745.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://kz-wintograce.com/img/537457745.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://kz-wintograce.com/img/537457745.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://kz-wintograce.com/img/537457745.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://kz-wintograce.com/img/537457745.png"} />
			<meta name={"msapplication-TileImage"} content={"https://kz-wintograce.com/img/537457745.png"} />
		</Helmet>
		<Components.Header />
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				DriveNow Hakkında Daha Fazla Bilgi Edinin
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0" text-align="left">
				DriveNow, olağanüstü araçlar ve mükemmel müşteri hizmetleri sunmaya adanmış bir lider araç kiralama hizmetidir. Misyonumuz, araç kiralama sürecini tüm müşterilerimiz için basit, rahat ve keyifli hale getirmektir. Yüksek kaliteli bir deneyim sunma konusunda tutkuluyuz ve ne zaman ihtiyacınız olursa olsun size güvenilir bir araç sağlamayı garanti ederiz.
				<br />
				<br />
				Ekibimiz, araç kiralama sektöründe en yüksek standartları korumaya adanmış deneyimli profesyonellerden oluşmaktadır. Filomuzu yönetmekten müşteri desteğine kadar, her kiracının kesintisiz bir deneyim yaşamasını sağlamak için çalışıyoruz.
			</Text>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px">
					<Image src="https://kz-wintograce.com/img/3.jpg" width="320px" />
				</Box>
				<Box padding="10px">
					<Image src="https://kz-wintograce.com/img/4.jpg" width="320px" />
				</Box>
				<Box padding="10px" width="320px">
					<Image
						src="https://kz-wintograce.com/img/5.jpg"
						width="320px"
						max-width="100%" />
				</Box>
			</Box>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" background="#e2e8f0" quarkly-title="About-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="100%" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="--color-darkL2"
				md-padding="48px 40px 48px 40px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--light"
					font="--headline2"
					lg-text-align="left"
					sm-font="--headline3"
				>
					Hizmetlerimiz
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--light"
					font="--base"
					lg-text-align="left"
					md-margin="0px 0px 28px 0px"
				>
					Kısa Dönem Kiralama: Tatil veya geçici ihtiyaçlar için günlük veya haftalık kiralama için esnek seçenekler.
					<br />
					<br />
					Uzun Dönem Kiralama: Hem kişisel hem de ticari kullanım için uygun fiyatlı uzun dönem kiralama çözümleri.
					<br />
					<br />
					Şirket Kiralama: Birden fazla araç seçeneği ve özel hizmetler içeren, iş dünyasına yönelik kişiselleştirilmiş paketler.
					<br />
					<br />
					Özel Etkinlikler: Düğünler, yıldönümleri veya kurumsal etkinlikler için premium araç kiralama.
					<br />
					<br />
					Havalimanı Transferi: Sorunsuz seyahat için büyük havalimanlarına gidiş-dönüş hizmetleri.
					<br />
					<br />
					Tüm araç kiralama ihtiyaçlarınızı karşılayacak kapsamlı hizmetler sunmaya kararlıyız.
				</Text>
				<Link
					href="/contacts"
					color="--lightD1"
					text-decoration-line="initial"
					padding="7px 24px 8px 24px"
					background="--color-primary"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					text-transform="uppercase"
					letter-spacing="1px"
					border-radius="4px"
				>
					Bize Ulaşın
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
				lg-order="-1"
			>
				<Image
					src="https://kz-wintograce.com/img/6.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					object-position="0% 70%"
					sm-max-height="500px"
					height="100%"
				/>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://kz-wintograce.com/img/7.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
						max-height="500px"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://kz-wintograce.com/img/8.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					İhtiyaçlarınız için mükemmel aracı bulmaya hazır mısınız? DriveNow filosunu keşfedin ve bugün araç kiralama rezervasyonunuzu yapın. Birinci sınıf hizmetimizi deneyimleyin ve güvenle sürüş yapın!
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65201646cd81ee001f7ce215"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});